import DocxBuilder from "../docxgen/DocxBuilder";



export default class CareerReportBuilder extends DocxBuilder {
    constructor() {
        super();
        this.aiOptimizedPhrasing = "";
        this.selectedResumeName = "";
    }

    CreateText(aiOptimizedPhrasing, selectedResumeName) {
        this.aiOptimizedPhrasing = aiOptimizedPhrasing;
        this.selectedResumeName = selectedResumeName;
    }

    AddFormatedLines(text) {
        const lines = text.split('\n');
        lines.forEach(line => {
            this.AddParagraphEmpty(line);
        });
    }

    BuildDocx(title) {
        this.AddTitle(title)
        this.AddParagraph(`Generated: ${new Date().toLocaleDateString()}`)
        this.AddParagraph(`Based on: ${this.selectedResumeName}`);
        this.AddParagraphEmpty("");
        this.AddFormatedLines(this.aiOptimizedPhrasing);
        return this.BuildSection().GetBlob();
    }
}